import React from 'react';

function App() {
  return (
    <div className="bg-gray-100 min-h-screen p-4">
      <h1 className="text-2xl font-bold text-blue-600">வணக்கம் Tailwind CSS!</h1>
      <p className="mt-2 text-gray-700">இது Tailwind CSS-ஐ React-ல் பயன்படுத்துவதற்கான எளிய உதாரணம்.</p>
      <code className="block mt-4 p-2 bg-gray-200 rounded text-sm font-mono">
        console.log('Hello, Tailwind!');
      </code>
    </div>
  );
}

export default App;