import React from 'react';
import { createRoot } from 'react-dom/client'; // React 18-ல் புதிய Syntax
import App from './App';

// Root element-ஐ கண்டறியவும்
const container = document.getElementById('root');

// createRoot-ஐ உருவாக்கவும்
const root = createRoot(container);

// App-ஐ Render செய்யவும்
root.render(
  // <React.StrictMode>
    <App />
  // </React.StrictMode>
);